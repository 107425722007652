<template>
  <table
    class="table table-bordered table-striped"
    role="grid"
  >
    <colgroup>
      <col style="width: 41.77%;">
      <col style="width: 20.18%;">
      <col style="width: 21.73%;">
      <col style="width: 16.3%;">
    </colgroup>
    <thead>
      <tr
        role="row"
        class="tablesorter-headerRow"
      >
        <th
          style="text-align: center; user-select: none; min-width: 8px; max-width: none;"
          data-column="0"
          tabindex="0"
          scope="col"
          role="columnheader"
        >
          <div class="tablesorter-header-inner">
            Действие/Страница
          </div>
        </th>
        <th
          data-column="1"
          tabindex="0"
          scope="col"
          role="columnheader"

          style="user-select: none; min-width: 8px; max-width: none;"
        >
          <div class="tablesorter-header-inner">
            Администратор
          </div>
        </th>
        <th
          data-column="2"
          tabindex="0"
          scope="col"
          role="columnheader"

          style="user-select: none; min-width: 8px; max-width: none;"
        >
          <div class="tablesorter-header-inner">
            Менеджер
          </div>
        </th>
        <th
          data-column="3"
          tabindex="0"
          scope="col"
          role="columnheader"
          style="user-select: none; min-width: 8px; max-width: none;"
        >
          <div class="tablesorter-header-inner">
            Просмотр
          </div>
        </th>
      </tr>
    </thead>

    <tbody
      aria-live="polite"
      aria-relevant="all"
    >
      <tr role="row">
        <td colspan="1">
          <p>Редактирование общих данных компании</p>
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          <p>Редактирование реквизитов компании</p>
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          <span>Создание водителя</span>
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Редактирование водителя
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Удаление водителя</a>
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Отправка водителя на верификацию
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Создание ТС
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Редактирование созданного ТС<
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Отправка созданного ТС на верификацию
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Удаление ТС
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Создание контейнера
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          <span>Удаление контейнера</span>
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Приглашение новых пользователей Перевозчика
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Удаление приглашенных пользователей Перевозчика
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Редактирование Рейса(указать водителей, тс, контейнеры)
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Подачи заявки на готовность к выполнению Рейса
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Подача заявки на отказ от выполнения Рейса
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Редактирование/блокировка приглашенных
          пользователей Перевозчика
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Участие в аукционе(подача ставок и встречных
          предложений)
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Взятие выигранного в аукционе рейса в
          обработку
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Отказ от взятия выигранного в аукционе рейса
          в обработку
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Участие в тендере(подача
          предложений)
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Взятие выигранных в тендере рейсов в
          обработку
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
      <tr role="row">
        <td colspan="1">
          Отказ от взятия выигранных в тендере
          рейсов в обработку
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1">
          <span
            class="fa fa-check text-success bold"
          />
        </td>
        <td colspan="1" />
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'ContractorUsersRolesInfoTable',
};
</script>

<style scoped>

</style>
